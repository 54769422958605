// mockProjects.js
export const ArtImages = [
    'https://cdna.artstation.com/p/assets/images/images/065/831/956/large/wan-uin-futuristic-chrismas-and-d.jpg?1691349310',
    'https://cdnb.artstation.com/p/assets/images/images/065/842/063/large/wan-uin-220727-medium.jpg?1691384769',
    'https://cdna.artstation.com/p/assets/images/images/065/933/292/large/wan-uin-220203-done.jpg?1691596001',
    'https://cdnb.artstation.com/p/assets/images/images/065/842/019/large/wan-uin-200717.jpg?1691384498',
    'https://cdna.artstation.com/p/assets/images/images/065/933/728/large/wan-uin-normal.jpg?1691596623',
    'https://cdnb.artstation.com/p/assets/images/images/065/935/637/large/wan-uin-home1.jpg?1691599741',
    'https://cdnb.artstation.com/p/assets/images/images/065/935/835/large/wan-uin-2.jpg?1691600077',
    'https://cdnb.artstation.com/p/assets/images/images/065/933/125/large/wan-uin-210816-2.jpg?1691595535',
    'https://cdna.artstation.com/p/assets/images/images/065/841/960/large/wan-uin-210816.jpg?1691384467',
    'https://cdnb.artstation.com/p/assets/images/images/065/934/411/large/wan-uin-whatsapp-image-2022-09-08-at-11-54-35.jpg?1691597848',
    'https://cdna.artstation.com/p/assets/images/images/065/934/310/large/wan-uin-title.jpg?1691597638',
    'https://cdnb.artstation.com/p/assets/images/images/065/933/807/large/wan-uin-9de12672-c380-42c6-a22b-170631a5eddf.jpg?1691596747',
    'https://cdnb.artstation.com/p/assets/images/images/065/933/807/large/wan-uin-9de12672-c380-42c6-a22b-170631a5eddf.jpg?1691596747',
    'https://cdnb.artstation.com/p/assets/images/images/065/934/711/large/wan-uin-hi-world-min.jpg?1691598172',
    'https://cdnb.artstation.com/p/assets/images/images/065/933/027/large/wan-uin-200703.jpg?1691595378',
    'https://cdnb.artstation.com/p/assets/images/images/065/933/041/large/wan-uin-200706.jpg?1691595397',
    'https://cdnb.artstation.com/p/assets/images/images/065/933/175/large/wan-uin-u-medium.jpg?1691596178',
    'https://cdnb.artstation.com/p/assets/images/images/065/933/179/large/wan-uin-h-medium.jpg?1691595648',
    'https://cdna.artstation.com/p/assets/images/images/065/933/170/large/wan-uin-o-medium.jpg?1691595630',
    'https://cdnb.artstation.com/p/assets/images/images/065/935/601/large/wan-uin-1.jpg?1691599696',
    'https://cdnb.artstation.com/p/assets/images/images/065/935/301/large/wan-uin-.jpg?1691599159',
    'https://cdna.artstation.com/p/assets/images/images/065/935/310/large/wan-uin-syia2.jpg?1691599179',
    'https://cdna.artstation.com/p/assets/images/images/065/935/524/large/wan-uin-17.jpg?1691599580',
    'https://cdnb.artstation.com/p/assets/images/images/065/935/845/large/wan-uin-4.jpg?1691600101',
    'https://cdna.artstation.com/p/assets/images/images/065/935/860/large/wan-uin-3.jpg?1691600126',
  ];